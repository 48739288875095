<template>
    <div class="row">
        <div class="col-12">
            <div class="group-selecting">
                <p v-if="question" :class="['anketa', {'error' : error}]" v-html="question" />
                <rwm-radiobox
                        :ref="refTrue"
                        :value="valueTrue"
                        :label="labelTrue"
                        :label-position="labelPosition"
                        :name="name"
                        :checked="checkedTrue"
                        :mark="markOne"
                        @change="onGroupSelecting"
                        :disabled="disabled"
                />
                <rwm-radiobox
                        :ref="refFalse"
                        :value="valueFalse"
                        :checked="checkedFalse"
                        :label="labelFalse"
                        :label-position="labelPosition"
                        :name="name"
                        :mark="markTwo"
                        @change="onGroupSelecting"
                        :disabled="disabled"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import RwmRadiobox from "../../../../../RWMRadioBox/RwmRadioBox";
    export default {
        name: "GroupSelecting",
        components: {RwmRadiobox},
        props: {
            markOne: {
                type: Object,
                default: null
            },
            markTwo: {
                type: Object,
                default: null
            },
            disabled: Boolean,
            question: [String, null],
            labelTrue: String,
            labelFalse: String,
            valueTrue: [String, Boolean, Number],
            valueFalse: [String, Boolean, Number],
            labelPosition: String,
            name: String,
            checkedTrue: {
                type: Boolean,
                default: false
            },
            checkedFalse: {
                type: Boolean,
                default: false
            },
            refTrue: {
                type: [String, null],
                default: null
            },
            refFalse: {
                type: [String, null],
                default: null
            },
            setClick: [String, null],
            selector: [String, Number, Boolean],
            error: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            newValue: {
                get() {
                    return this.selector
                },
                set(val) {
                    this.$emit('onGroupSelecting', val)
                }
            }
        },
        model: {
            prop: "selector",
            event: "onGroupSelecting"
        },
        watch: {
            setClick: {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.$refs[val].$el.click()
                    }
                }
            }
        },
        methods: {
            onGroupSelecting(val) {
                this.newValue = val
            },
        }
    }
</script>

<style scoped lang="scss">
    .group-selecting {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        column-gap: 75px;
        row-gap: 24px;
        & .anketa {
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: auto;
            }
        }
        & .error {
            color: $RWM-red
        }
        @media screen and (min-width: 601px) {
          justify-content: flex-start;
        }
        @media screen and (min-width: 1024px) {
            flex-wrap: nowrap;
            justify-content: initial;
            align-items: center;
            & .anketa {
                flex: 1 1 auto;
            }
        }
    }
</style>
