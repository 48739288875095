<template>
  <fatca
    :user="getUSER"
    :timer="timer"
    :show-checking="showChecking"
    :citizenship="citizenship"
    :residence="residence"
    :UsTaxResident="UsTaxResident"
    :bornState="bornState"
    :renouncedCitizenship="renouncedCitizenship"
    :renouncedCitizenshipFile="_renouncedCitizenshipFile"
    :otherReasonsOfRenounced="otherReasonsOfRenounced"
    :otherReasonsOfRenouncedFile="_otherReasonsOfRenouncedFile"
    :notRenounced="notRenounced"
    :hasUsAddress="hasUsAddress"
    :hasUsPhone="hasUsPhone"
    :taxResident="taxResident"
    :ssnitin="_ssnitin"
    :ssnNumber="ssnNumber"
    :engName="engName"
    :disabled="timer"
    @onSubmit="onSubmit"
    @sendAgain="onSubmit"
    @timer="setTimer"
    @verified="onVerififed"
    @update="onUpdate"
    @fileLoad="onFileLoad"
    @fileDelete="onFileDelete"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Fatca from "../../../../components/pages/cabinet/documents/forms/Fatca";
import RWMSendingDocumentsMessageMixin from "../../../../components/mixins/RWMSendingDocumentsMessageMixin";
import moment from "moment";

let model = {
  haveUsaCitizen: false, // Являетесь ли Вы гражданином США (в том числе при наличии двух и более гражданств)?
  usaNationality: null, // Вы не отказывались от гражданства США и у вас нет иных оснований отсутствия гражданства США.
  haveUsaGreenCard: false, // Имеете ли Вы разрешение на постоянное пребывание (вид на жительство) в США (являетесь владельцем Green Card)?
  usaLongTermStay: false, // Соответствуете ли Вы критериям «долгосрочного пребывания в США» для признания налогоплательщиком – налоговым резидентом США?
  bornState: false, // Является ли местом Вашего рождения территория США?
  usaLossNationality: null, // Вы отказались от гражданства США.
  usaOtherLossNationality: null, // Имеются иные основания отсутствия гражданства США.
  haveUsaAddress: null, // Имеете ли Вы адрес проживания и (или) почтовый адрес на территории США?
  haveUsaPhone: null, // Имеете ли Вы номер контактного телефона и (или) факса на территории США?
  usaTaxResident: null, //Являетесь ли Вы налоговым резидентом США?
  fullnameEnglish: null, // Фамилия, имя, отчество на английском языке
  ssn: null,
  itin: null,
  ssnItinNumber: null,
  signed: false,
};

const mode = {
  renouncedCitizenshipFile: 3, // Вы отказались от гражданства США: 3
  otherReasonsOfRenouncedFile: 4, // Имеются иные основания отсутствия гражданства США: 4
};

export default {
  name: "FormFatca",
  components: { Fatca },
  mixins: [RWMSendingDocumentsMessageMixin],
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("DocumentsModule", ["getDRAFT"]),
    bornState() {
      return this.getDRAFT ? this.getDRAFT.data.bornState : null;
    },
    citizenship() {
      return this.getDRAFT ? this.getDRAFT.data.haveUsaCitizen : null;
    },
    residence() {
      return this.getDRAFT ? this.getDRAFT.data.haveUsaGreenCard : null;
    },
    UsTaxResident() {
      return this.getDRAFT ? this.getDRAFT.data.usaLongTermStay : null;
    },
    renouncedCitizenship() {
      return this.getDRAFT ? this.getDRAFT.data.usaLossNationality : null;
    },
    otherReasonsOfRenounced() {
      return this.getDRAFT ? this.getDRAFT.data.usaOtherLossNationality : null;
    },
    notRenounced() {
      return this.getDRAFT ? this.getDRAFT.data.usaNationality : null;
    },
    hasUsAddress() {
      return this.getDRAFT ? this.getDRAFT.data.haveUsaAddress : null;
    },
    hasUsPhone() {
      return this.getDRAFT ? this.getDRAFT.data.haveUsaPhone : null;
    },
    taxResident() {
      return this.getDRAFT ? this.getDRAFT.data.usaTaxResident : null;
    },
    ssnNumber() {
      return this.getDRAFT ? this.getDRAFT.data.ssnItinNumber : null;
    },
    engName() {
      return this.getDRAFT ? this.getDRAFT.data.fullnameEnglish : null;
    },
    _ssnitin() {
      return this.getDRAFT
        ? this.getDRAFT.data.itin
          ? "ITIN"
          : this.getDRAFT.data.ssn
          ? "SSN"
          : null
        : null;
    },
    _renouncedCitizenshipFile: {
      get() {
        if (
          this.getDRAFT &&
          this.getDRAFT.files.length > 0 &&
          this.getDRAFT.files.find((el) => el.file_type === 3)
        ) {
          return this.getDRAFT.files.find((el) => el.file_type === 3);
        } else {
          return this.renouncedCitizenshipFile;
        }
      },
      set(val) {
        this.renouncedCitizenshipFile = val;
      },
    },
    _otherReasonsOfRenouncedFile: {
      get() {
        if (
          this.getDRAFT &&
          this.getDRAFT.files.length > 0 &&
          this.getDRAFT.files.find((el) => el.file_type === 4)
        ) {
          return this.getDRAFT.files.find((el) => el.file_type === 4);
        } else {
          return this.otherReasonsOfRenouncedFile;
        }
      },
      set(val) {
        this.otherReasonsOfRenouncedFile = val;
      },
    },
  },
  data() {
    return {
      timer: false,
      showChecking: false,
      renouncedCitizenshipFile: null,
      otherReasonsOfRenouncedFile: null,
      ssnitin: null,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("DocumentsModule", [
      "GET_DOCUMENTS",
      "CREATE_DOCUMENT",
      "UPDATE_DOCUMENT",
      "UPLOAD_FILE",
      "DELETE_FILE_FROM_DOCUMENT",
    ]),
    onUpdate(val) {
      let key = Object.keys(val)[0];
      this.showChecking = false;

      let mode = {
        citizenship: "haveUsaCitizen",
        residence: "haveUsaGreenCard",
        UsTaxResident: "usaLongTermStay",
        bornState: "bornState",
        renouncedCitizenship: "usaLossNationality",
        otherReasonsOfRenounced: "usaOtherLossNationality",
        notRenounced: "usaNationality",
        hasUsAddress: "haveUsaAddress",
        hasUsPhone: "haveUsaPhone",
        taxResident: "usaTaxResident",
        ssn: "ssn",
        itin: "itin",
        ssnNumber: "ssnItinNumber",
        engName: "fullnameEnglish",
        renouncedCitizenshipFile: "renouncedCitizenshipFile",
        otherReasonsOfRenouncedFile: "otherReasonsOfRenouncedFile",
      };
      // let data = {...this.getDRAFT.data}
      let data = {};
      if (key === "ssnitin") {
        let temp_key = key;
        if (val[key] === "SSN") {
          temp_key = "ssn";
          data[mode[temp_key]] = true;
          data[mode["itin"]] = false;
        } else if (val[key] === "ITIN") {
          temp_key = "itin";
          data[mode[temp_key]] = true;
          data[mode["ssn"]] = false;
        }
      } else {
        data[mode[key]] = val[key];
        let keys = [
          "otherReasonsOfRenounced",
          "renouncedCitizenship",
          "notRenounced",
        ];
        if (keys.find((el) => key === el)) {
          keys.splice(keys.indexOf(key), 1);
          if (data[mode[key]]) {
            for (let other of keys) {
              data[mode[other]] = false;
            }
          }
        }
      }
      this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });
    },
    setTimer(val) {
      this.timer = val;
    },
    onSubmit() {
      this.REQUEST_SMS({ phone: this.getUSER.phone, doc_id: this.getDRAFT ? this.getDRAFT.id : null, action: 'document_sign' }).then((success) => {
        if (success) {
          this.timer = true;
          this.showChecking = true;
        } else this.getERROR ? this.$toast.error(this.getERROR) : "";
      });
    },
    onVerififed(data) {
      this.CONFIRM_SMS({ phone: this.getUSER.phone, key: data.code }).then(
        (success) => {
          if (success) {
            let data = { ...this.getDRAFT.data };
            data.date_sign = moment(new Date()).format("X");
            data.signed = true;
            this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });
            this.show_sending_documents_message();
            setTimeout(() => {
              if (typeof this.$route.query.next !== "undefined" && this.$route.query.next !== "")
                this.$router.push("/cabinet/investment/purchase-of-investment-shares/" + this.$route.query.next + "?next")
              else if (typeof this.$route.query.to !== "undefined")
                this.$router.push("/cabinet/documents?next");
              else
                this.$router.push("/cabinet/documents/FATCA");
            }, 1000);
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    onFileLoad(file, type) {
      if (file.size > 5242880) {
        this.$toast.error("Размер файла превышает 5mb");
      } else {
        this.UPLOAD_FILE({
          file: file,
          type: mode[type],
          id: this.getDRAFT.id,
        }).then((response) => {
          if (response.success) {
            this[`_${type}`] = response.file;
            this.$toast.success("Файл добавлен в черновик");
          } else this.$toast.error(`${this.getERROR}`);
        });
      }
    },
    onFileDelete(type) {
      this.DELETE_FILE_FROM_DOCUMENT({ id: this[`_${type}`].id }).then(
        (response) => {
          if (response) {
            this.GET_DOCUMENTS("qst-fatca").then((resp) => {
              if (resp) this[`_${type}`] = null;
              this.showChecking = false;
            });
          } else this.$toast.error("Файл не был удален");
        }
      );
    },
  },
  mounted() {
    model.user = this.getUSER;
    this.GET_DOCUMENTS("qst-fatca").then((resp) => {
      if (resp) {
        if (!this.getDRAFT) {
          this.CREATE_DOCUMENT({
            alias: "qst-fatca",
            data: model,
          }).then((response) =>
            !response
              ? this.$toast.error(`${this.getERROR}`)
              : this.GET_DOCUMENTS("qst-fatca")
          );
        }
      }
    });
  },
};
</script>
