<template>
    <div class="file-loader-component">
        <div v-if="!filename" class="load-img">
            <input :disabled="disabled" type="file" accept="image/jpeg, image/gif, image/png, application/pdf" :ref="refer" @change="onFileChange"/>
            <rwm-button :error="error" :disabled="disabled" width="100%" outlined @click="fileUpload(refer)">{{title}}
                <template v-slot:prepend-img>
                    <v-icon style="padding-right: 24px" :hovering="false">
                        upload-doc
                    </v-icon>
                </template>
            </rwm-button>
        </div>
        <rwm-file-loaded :disabled="disabled" v-else :filename="filename" @deleteFile="$emit('deleteFile')" @openFile="$emit('openFile')"/>
    </div>
</template>

<script>
    import RwmButton from "../RWMButton/RwmButton";
    import VIcon from "../icons/VIcon";
    import RwmFileLoaded from "./RwmFileLoaded";

    export default {
        name: "RwmInputFile",
        components: {RwmFileLoaded, VIcon, RwmButton},
        props: {
            error: {
                type: Boolean,
                default: false
            },
            refer: String,
            title: String,
            filename: {
                type: String || undefined,
                default: undefined
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            fileUpload(ref) {
                this.$refs[ref].value=''
                this.$refs[ref].click()
            },
            onFileChange(event) {
                this.$emit('FileChange', event)
            }
        }
    }
</script>

<style lang="scss">
    .file-loader-component {
        display: flex;
        width: 100%;
        height: 100%;
        .load-img {
            position: relative;
            width: 100%;
            & input {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                width: 100%;
                z-index: 0;
            }
        }
    }
</style>